<template>
  <div class="component-drawing">
    <div class="sticky-parent">
      <div id="tx-drawing-buttons" class="sticky-top mt-2 mr-2">
        <b-button-group class="mr-3" size="sm">
          <b-button
            class="px-auto mx-auto width-45px"
            variant="outline-primary"
            :pressed="!eraserMode"
            @click="eraserMode = false"
            @touchstart="eraserMode = false"
          >
            <i class="fas fa-pen"></i>
          </b-button>
          <b-button
            class="mx-auto width-45px"
            variant="outline-info"
            :pressed="eraserMode"
            @click="eraserMode = true"
            @touchstart="eraserMode = true"
          >
            <i class="fas fa-eraser"></i>
          </b-button>
        </b-button-group>
        <b-button-group class="mr-3" size="sm">
          <VSwatches v-model="color" popover-x="right">
            <b-button
              class="px-auto mx-auto width-45px rounded-left"
              slot="trigger"
              size="sm"
              :style="
                'background-color:' +
                color +
                ';cursor: pointer; height: 31px;border-radius: 0px'
              "
              @click="eraserMode = false"
              ><i class="fas fa-palette"></i
            ></b-button>
          </VSwatches>
          <b-button
            id="btn-thickness"
            class="width-45px"
            variant="light"
            size="sm"
            @click="eraserMode = false"
          >
            <hr
              class="m-auto border-0 rounded"
              :style="{
                height: thickness + 'px',
                backgroundColor: color,
              }"
            />
          </b-button>
        </b-button-group>
        <b-button-group class="mr-3" size="sm">
          <b-button
            variant="primary"
            :disabled="historyStep <= 0"
            @click="undoStroke"
          >
            <i class="fas fa-undo"></i>
          </b-button>
          <b-button
            variant="info"
            :disabled="historyStep === historyLines.length"
            @click="redoStroke"
          >
            <i class="fas fa-redo"></i>
          </b-button>
        </b-button-group>
        <b-button-group class="mr-3" size="sm">
          <!-- <b-button variant="success" @click="saveLinesToLocalStorage">
            <i class="fas fa-save"></i>
          </b-button> -->
          <b-button variant="warning" @click="loadLinesFromLocalStorage">
            <i class="fas fa-paste"></i>
          </b-button>
        </b-button-group>

        <b-button class="mr-2" size="sm" @click="clearLines" variant="danger">
          <i class="fas fa-trash-alt"></i>
        </b-button>
      </div>
    </div>
    <div class="overflow-hidden">
      <v-stage
        id="stage"
        ref="stage"
        :config="configKonva"
        @mousedown="mouseDrawing"
        @touchstart="
          pencilDrawing($event);
          touchEraser($event);
        "
        @mouseup="stopDrawing"
        @touchend="stopDrawing"
        @mousemove="drawing"
        @touchmove="drawing"
      >
        <!-- <v-layer ref="background"> </v-layer> -->
        <v-layer ref="layer">
          <v-rect :config="configBackground"></v-rect>
          <v-text :config="configText"></v-text>
          <v-line v-for="line in lines" :key="line.id" :config="line"></v-line>
          <!-- <v-rect :config="configForeground"></v-rect> -->
        </v-layer>
        <!-- <v-layer ref="foreground"> </v-layer> -->
      </v-stage>
    </div>

    <b-popover
      id="popoverThickness"
      class="p-0"
      target="btn-thickness"
      placement="bottom"
      triggers="click blur"
    >
      <b-button-group>
        <b-button
          variant="light"
          :pressed="thickness == 0.6"
          @click="(thickness = 0.6), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '1px',
              width: '1px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 1.2"
          @click="(thickness = 1.2), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '2px',
              width: '2px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 2"
          @click="(thickness = 2), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '3px',
              width: '3px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 4"
          @click="(thickness = 4), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '4px',
              width: '4px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 9"
          @click="(thickness = 9), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '9px',
              width: '9px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 14"
          @click="(thickness = 14), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '14px',
              width: '14px',
            }"
          ></span
        ></b-button>
        <b-button
          variant="light"
          :pressed="thickness == 20"
          @click="(thickness = 20), hidePopover()"
          ><span
            class="spanDot"
            :style="{
              height: '20px',
              width: '20px',
            }"
          ></span
        ></b-button>
      </b-button-group>
    </b-popover>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
// import Vue from "vue";
// import VueKonva from "vue-konva";
// Vue.use(VueKonva);

import noteTemplate from "@/assets/img/noteTemplate.jpeg";

import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import { eventBus } from "@/main";
import Dialog from "@/components/modal/Dialog";

import "@/theme/modal/PaymentHistory/Drawing.scss";

export default {
  name: "Drawing",
  components: {
    VSwatches,
    Dialog,
  },
  props: ["patient", "drawNote", "summaryTableBusy"],
  data() {
    return {
      configKonva: {
        width: 720,
        height: 960,
      },
      configBackground: {
        x: 0,
        y: 0,
        width: 720,
        height: 960,
        fillPatternImage: null,
        preventDefault: false,
      },
      configText: {
        x: 15,
        y: 10,
        text: "",
        fontSize: 14,
        lineHeight: 1.5,
        fill: "#3D556E",
      },
      // configForeground: {
      //   x: 0,
      //   y: 0,
      //   width: 720,
      //   height: 960,
      //   preventDefault: false,
      // },
      lines: [],
      lastSavedDrawNote: { text: {}, lines: [] },

      lineWidth: 0,
      isTouch: false,
      points: [],
      lastline: {},

      eraserMode: false,

      thickness: 2,
      color: "#2980B9",

      //history
      historyStep: 0,
      historyLines: [],
      linesEdited: false,

      //Konva objects
      stage: null,

      isPen: false,
    };
  },
  created() {},
  watch: {},
  computed: {
    ...mapGetters({
      getUserInfo: "moduleUser/getUserInfo",
    }),
    newDrawNote() {
      return {
        text: this.configText.text || "",
        lines: this.lines || [],
      };
    },
  },
  methods: {
    //drawing
    mouseDrawing(e) {
      this.startDrawing(e);
      this.startObjEraser(e);
    },
    pencilDrawing(e) {
      if (!this.isPen) return;
      e.evt.preventDefault();
      if (this.eraserMode) {
        this.startObjEraser(e);
      } else {
        this.startDrawing(e);
      }
    },
    startDrawing(e) {
      if (!this.eraserMode) {
        const pos = this.stage.getPointerPosition();
        this.isTouch = true;
        this.lastLine = {
          lineCap: "round",
          lineJoin: "round",
          stroke: this.color,
          strokeWidth: this.thickness,
          globalCompositeOperation: "source-over",
          points: [pos.x, pos.y],
          tension: 0.5,
        };
        this.lines = this.lines.concat([this.lastLine]);
      }
    },
    stopDrawing(e) {
      if (!this.isTouch) return;

      if (this.eraserMode) {
        this.lines.pop();
        if (this.linesEdited) {
          this.addHistory();
          this.linesEdited = false;
        }
      } else {
        // simplifeidPoints ลากเส้นเสร็จ
        let points = this.chunkArrayInGroups(this.lastLine.points, 2); //grouping array
        let simplifiedPoints = this.simplifyLineRDP(points, 1); //ลดจำนวน points ทำให้ เส้น smooth มากขึ้น ตัวเลข คือระยะหว่างจุดที่จะนำมาคำนวน
        this.lastLine.points = [].concat.apply([], simplifiedPoints); //flatten array

        // this.stage.batchDraw();
        this.addHistory();
      }
      this.isTouch = false;

      this.$emit("update:drawNote", this.newDrawNote);
    },

    drawing(e) {
      if (!this.isTouch) {
        return;
      }
      const pos = this.stage.getPointerPosition();
      this.lastLine.points = this.lastLine.points.concat([pos.x, pos.y]);

      //**  realtime simplifiedPoints
      // let points = this.chunkArrayInGroups(this.lastLine.points, 2); //grouping array
      // let simplifiedPoints = this.simplifyLineRDP(points, 0.5); //ลดจำนวน points ทำให้ เส้น smooth มากขึ้น ตัวเลข คือระยะหว่างจุดที่จะนำมาคำนวน
      // this.lastLine.points = [].concat.apply([], simplifiedPoints); //flatten array
      // this.layer.batchDraw();

      if (this.eraserMode) {
        this.objErasing();
      }
    },

    //undo
    undoStroke() {
      if (this.historyStep <= 0) return;
      this.historyStep -= 1;
      this.lines = this.historyLines[this.historyStep - 1] || [];
    },
    redoStroke() {
      if (this.historyStep === this.historyLines.length) return;

      this.historyStep += 1;
      this.lines = this.historyLines[this.historyStep - 1] || [];
    },
    addHistory() {
      this.historyLines = this.historyLines.slice(0, this.historyStep);

      this.historyLines.push([...this.lines]);
      this.historyStep += 1;
      this.saveLinesToLocalStorage();
    },

    //eraser
    touchEraser(e) {
      // if (!e.evt.touches[0].force && e.evt.touches[0]) {
      //   const pos = this.stage.getPointerPosition();
      //   this.isTouch = true;
      //   this.lastLine = {
      //     lineCap: "round",
      //     lineJoin: "round",
      //     stroke: "white",
      //     strokeWidth: 25,
      //     bezier: true,
      //     globalCompositeOperation: "destination-out",
      //     points: [pos.x, pos.y],
      //   };
      //   this.lines.push(this.lastLine);
      // }
    },
    startObjEraser(e) {
      if (this.eraserMode) {
        this.isTouch = true;
        const pos = this.stage.getPointerPosition();
        this.lastLine = {
          lineCap: "round",
          lineJoin: "round",
          stroke: "#D3D3D3",
          strokeWidth: 5,
          bezier: true,
          globalCompositeOperation: "source-over",
          points: [pos.x, pos.y],
        };
        this.lines = this.lines.concat([this.lastLine]);
      }
    },
    objErasing() {
      let length = 50;
      this.lastLine.points.splice(
        -length - 2,
        this.lastLine.points.length - length
      );
      let eraserPoints = this.lastLine.points.slice(-4);
      this.lines.forEach((line, index) => {
        if (this.lines.length - 1 === index) return;
        let points = [...line.points];
        while (points.length >= 4) {
          let linePoints = points.slice(0, 4);
          let int = this.intersect(...eraserPoints, ...linePoints);
          if (int) {
            this.lines.splice(index, 1);
            this.linesEdited = true;
            break;
          }
          points.splice(0, 2);
        }
      });
    },

    intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
      if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false;
      }
      let denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
      if (denominator === 0) {
        return false;
      }
      let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
      let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;
      if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false;
      }
      let x = x1 + ua * (x2 - x1);
      let y = y1 + ua * (y2 - y1);
      return { x, y };
    },
    chunkArrayInGroups(arr, size) {
      var result = [];
      for (var i = 0; i < arr.length; i += size)
        result.push(arr.slice(i, i + size));
      return result;
    },
    simplifyLineRDP(points, length) {
      var simplify = function (start, end) {
        // recursize simplifies points from start to end
        var maxDist, index, xx, yy, dx, dy, ddx, ddy, p1, p2, p, t, dist, dist1;
        p1 = points[start];
        p2 = points[end];
        xx = p1[0];
        yy = p1[1];
        ddx = p2[0] - xx;
        ddy = p2[1] - yy;
        dist1 = ddx * ddx + ddy * ddy;
        maxDist = length;
        for (var i = start + 1; i < end; i++) {
          p = points[i];
          if (ddx !== 0 || ddy !== 0) {
            t = ((p[0] - xx) * ddx + (p[1] - yy) * ddy) / dist1;
            if (t > 1) {
              dx = p[0] - p2[0];
              dy = p[1] - p2[1];
            } else if (t > 0) {
              dx = p[0] - (xx + ddx * t);
              dy = p[1] - (yy + ddy * t);
            } else {
              dx = p[0] - xx;
              dy = p[1] - yy;
            }
          } else {
            dx = p[0] - xx;
            dy = p[1] - yy;
          }
          dist = dx * dx + dy * dy;
          if (dist > maxDist) {
            index = i;
            maxDist = dist;
          }
        }

        if (maxDist > length) {
          // continue simplification while maxDist > length
          if (index - start > 1) {
            simplify(start, index);
          }
          newLine.push(points[index]);
          if (end - index > 1) {
            simplify(index, end);
          }
        }
      };
      var end = points.length - 1;
      var newLine = [points[0]];
      simplify(0, end);
      newLine.push(points[end]);
      return newLine;
    },

    //clear
    clearLines() {
      this.$refs.Dialog.showAlertConfirm(
        'ลบการเขียนทั้งหมด"',
        "คุณต้องการลบข้อมูลการเขียนทั้งหมด?"
      ).then((res) => {
        if (res.isConfirmed) {
          this.lines = [];
          this.addHistory();
        }
      });
    },

    insertText() {
      if (this.drawNote.text) return;
      let patientFirstName;
      let patientLastName;
      patientFirstName = this.patient?.firstNameTh || "";
      patientLastName = this.patient?.lastNameTh || "";

      let userName =
        this.getUserInfo.firstNameTh + " " + this.getUserInfo.lastNameTh;
      let dateTimeRecord = moment().locale("th").format("D MMM YY H:mm");
      this.configText.text =
        dateTimeRecord +
        "\nชื่อคนไข้: " +
        patientFirstName +
        " " +
        patientLastName +
        "\nผู้บันทึก: " +
        userName;
      this.stage.batchDraw();
    },

    defaultValue() {
      this.lines = [];
      this.configText = {
        x: 15,
        y: 10,
        text: "",
        fontSize: 14,
        lineHeight: 1.5,
        fill: "#3D556E",
      };
      this.historyStep = 0;
      this.historyLines = [];
      this.lastSavedDrawNote = {};
      this.eraserMode = false;
    },

    hidePopover() {
      this.$root.$emit("bv::hide::popover", "popoverThickness");
    },

    retrieveDrawNote() {
      eventBus.$emit("returnDrawNote", this.drawNote);
      this.defaultValue();
    },
    assignDrawNote(e) {
      this.lines = [...e.lines];
      if (e?.text) {
        this.configText.text = e.text;
      }
      // this.stage.batchDraw();
    },
    saveLinesToLocalStorage() {
      if (this.lines?.length === 0) return;
      localStorage.setItem("drawingLines", JSON.stringify(this.lines));
    },
    async loadLinesFromLocalStorage() {
      let lines = (await localStorage.getItem("drawingLines")) || "";
      if (lines) {
        let res = await this.$refs.Dialog.showAlertConfirm(
          "Paste การเขียนล่าสุด",
          "ใช้ในกรณีระบบมีปัญหา เขียนไปแล้ว แต่ไม่สามารถบันทึกลงประวัติคนไข้ ระบบจะดึงการเขียนล่าสุดกลับมา"
        );
        if (res.isConfirmed) this.lines = JSON.parse(lines);
      } else {
        this.$refs.Dialog.showAlertInfo("ไม่มีข้อมูลกรเขียนที่ถูกบันทึกไว้");
      }
    },
  },
  mounted() {
    window.addEventListener("pointerdown", (e) => {
      this.isPen = e.pointerType === "pen";
    });
    eventBus.$on("setDefaultValuePaymentHistory", () => {
      this.configText.text = "";
      this.lines = [];
    });

    this.$nextTick(() => {
      this.stage = this.$refs.stage.getNode();
      // this.stage.height(960);
      // this.stage.width(720);

      const image = new window.Image();
      image.src = noteTemplate;
      image.onload = () => {
        this.configBackground.fillPatternImage = image;
      };
    });
  },
  beforeDestroy() {
    window.removeEventListener("pointerdown", () => {});

    // ลด memory leak ของ canvas ใน ios safari
    this.stage.height(1);
    this.stage.width(1);
    this.stage = null;
    // this.stage.destroy();
  },
};
</script>

<style>
.spanDot {
  background-color: #7e7e7e;
  border-radius: 50%;
  display: inline-block;
  display: flex;
  align-self: center;
}
#popoverThickness .popover-body {
  padding: 0px;
}
.sticky-parent {
  position: absolute;
  height: 100vmax;
  right: 0px;
}
.overflow-hidden {
  overflow: hidden;
}
</style>